import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import logo from "../../../images/logo.png";
import "./Navigation.css";

const Navigation = () => {
  const [activeNav, setActiveNav] = useState(false);

  const changeNavbarBg = () => {
    if (window.scrollY >= 50) {
      // console.log(window.scrollY);
      setActiveNav(true);
    } else {
      setActiveNav(false);
    }
  };

  // console.log(activeNav);

  window.addEventListener("scroll", changeNavbarBg);

  return (
    <div>
      <nav className={activeNav ? "fixed-top box-shadow bg-white" : "bg-white"}>
        <div className="container navbar navbar-expand-lg navbar-light my-nav">
          <div className="container-fluid">
            <NavLink className="navbar-brand" to="/">
              <img src={logo} alt="Digital Decoder Ltd" width="40%"
                />
            </NavLink>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse d-lg-flex justify-content-end"
              id="navbarSupportedContent"
              
            >
              <ul className="navbar-nav mb-lg-0">
                <li className="nav-item mt-lg-3 mx-1">
                  <NavLink
                    to="/"
                    className={`pb-2 px-2 mt-2 ${activeNav} ? text-black: text-black` }
                    aria-current="page"
                    style={{ textDecoration: "none",display: 'inline-block'}}
                  >
                    Home
                  </NavLink>
                </li>
                <li className="nav-item mt-lg-3 mx-1">
                  <NavLink
                    to="/joinmeeting"
                    className={`pb-2 px-2 mt-2 ${activeNav} ? text-black: text-black` }
                    aria-current="page"
                    style={{ textDecoration: "none",display: 'inline-block'}}
                  >
                    Join Meeting
                  </NavLink>
                </li>
               
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navigation;

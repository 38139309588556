import "./Home.css";
import React, { useState } from "react";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import Navigation from "../../Shared/Navigation/Navigation";
import meetingimage from "../../../images/meeting.png";
import { Link } from "react-router-dom";
const CreateMeeting = () => {
  const [username, setUsername] = useState("");
    const [language, setLanguage] = useState("");

    const zoomMeeting = () => {
      const data = {
          email: "rukucse11@gmail.com",
      };

      axios
          //.post(`http://localhost:5000/meeting`, data)
          .post(`https://zoomapienglish.taurisjapan-ai.com/meeting`, data)
          .then((response) => {

              let responseurl = response.data.join_url;

              console.log(responseurl);

              let newurl = '';
              if (language === '' || language == null)
              {
                  newurl = responseurl.replaceAll("https://us05web.zoom.us/j/", "https://zoomsdkenglish.taurisjapan-ai.com?") + `?role=1?name=${username}?lang=ja-JP?room=development`;
                  //newurl = responseurl.replaceAll("https://us05web.zoom.us/j/", "http://localhost:3006?") + `?role=1?name=${username}?lang=ja-JP?room=development`;
              }
              else
              {
               
                  newurl = responseurl.replaceAll("https://us05web.zoom.us/j/", "https://zoomsdkenglish.taurisjapan-ai.com?") + `?role=1?name=${username}?lang=${language}?room=development`;
                  //newurl = responseurl.replaceAll("https://us05web.zoom.us/j/", "http://localhost:3006?") + `?role=1?name=${username}?lang=${language}?room=development`;
              }
              window.location.replace(`${newurl}`);

          })
          .catch((err) => console.error(err));
  };

  return (
    <>
    
      <div>
      <div className="banner-wrapper">
      
      <div className="banner-contents">
        <div className="">
          <div className="banner-nav">
            <Navigation />
          </div>
          <header className="App-header">
                <div className="card">
                  
                    <h5>
                        Name&nbsp;&nbsp;
                        <input
                            type="text"
                            name="name"
                            style={{
                                width: "400px",
                                borderRadius: "5px",
                                padding: "8px 12px",
                                marginLeft: "30px",
                                fontSize: "18px",
                            }}
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                        />
                    </h5>
                    <h5>
                        Language&nbsp;&nbsp;
                        <select
                            type="text"
                            name="language"
                            style={{
                                width: "400px",
                                borderRadius: "5px",
                                padding: "8px 12px",
                                fontSize: "18px",
                            }}
                            value={language}
                            onChange={(e) => setLanguage(e.target.value)}

                        >
                            <option value="ja-JP">Japanese</option>
                            <option value="en-US">English</option>
                            
                        </select>
                    </h5>
                    <div className="row" style={{ margin: "10px" }}>
                        <div className="col-md-5">
                            <div
                                className="row"
                                style={{ margin: "10px", marginTop: "120px" }}
                            >
                                <button
                                    className="btn btn-info"
                                    style={{
                                        width: "290px",
                                        height: "80px",
                                        fontSize: "20px",
                                        fontFamily: "cursive",
                                    }}
                                    onClick={zoomMeeting}
                                >
                                    Create Meeting
                                </button>
                            </div>
                        </div>
                        <div className="col-md-7" style={{ float: "right" }}>
                            <img
                                src={meetingimage}
                                height="330px"
                                width="400px"
                                style={{
                                    margin: "10px",
                                    borderRadius: "50px",
                                }}
                                alt=""
                            />
                        </div>

                    </div>
                </div>
            </header>
        </div>
      

      </div>
      <div>
   
  </div>
    </div>
      </div>

    </>
  );
};

export default CreateMeeting;

import "./App.css";
import CreateMeeting from "./pages/Home/Home/Home";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Joinmeeting from "./pages/Joinmeeting/JoinMeeting";
import PageNotFound from "./pages/PageNotFound/PageNotFound";
import React from 'react';

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<CreateMeeting />} />
          <Route path="/home" element={<CreateMeeting />} />
          <Route path="/joinmeeting" element={<Joinmeeting />} />
          <Route path="*" element={<PageNotFound/>} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;

import React, { useState } from "react";
import "../Home/Home/Home.css";
import Footer from "../Shared/Footer/Footer";
import Fade from "react-reveal/Fade";
import meetingimage from "../../images/meeting.png";
import Navigation from "../Shared/Navigation/Navigation";

const JoinMeeting = () => {
  const [username, setUsername] = useState("");
  const [language, setLanguage] = useState("");
  const [meetinglink, setMeetingLink] = useState("");

  const zoomMeeting = () => {
    let responseurl = meetinglink;
    let newurl = '';
    if (language === '' || language == null) {
      newurl = responseurl.replaceAll("https://us05web.zoom.us/j/", "https://zoomsdkenglish.taurisjapan-ai.com?") + `?role=0?name=${username}?lang=ja-JP?room=development`;
      //newurl = responseurl.replaceAll("https://us05web.zoom.us/j/", "http://localhost:3006?") + `?role=0?name=${username}?lang=en-US?room=development`;
      //newurl=responseurl;
    }
    else {
      newurl = responseurl.replaceAll("https://us05web.zoom.us/j/", "https://zoomsdkenglish.taurisjapan-ai.com?") + `?role=0?name=${username}?lang=${language}?room=development`;
      //newurl = responseurl.replaceAll("https://us05web.zoom.us/j/", "http://localhost:3006?") + `?role=0?name=${username}?lang=${language}?room=development`;
    }
    window.location.replace(`${newurl}`);

  };
  return (
    <div>
      
      <div className="banner-wrapper">
        <div className="banner-contents">
        <div className="banner-nav">
            <Navigation />
          </div>
          <header className="App-header">

            <div className="card">
              <h5>
                Meeting Link:&nbsp;&nbsp;
                <input
                  type="text"
                  name="meetinglink"
                  style={{
                    width: "400px",
                    borderRadius: "5px",
                    padding: "8px 12px",
                    marginLeft: "0px",
                    fontSize: "18px",
                  }}
                  value={meetinglink}
                  onChange={(e) => setMeetingLink(e.target.value)}
                />
              </h5>

              <h5>
                Name&nbsp;&nbsp;
                <input
                  type="text"
                  name="name"
                  style={{
                    width: "400px",
                    borderRadius: "5px",
                    padding: "8px 12px",
                    marginLeft: "70px",
                    fontSize: "18px",
                  }}
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </h5>
              <h5>
                Language&nbsp;&nbsp;
                <select
                  type="text"
                  name="language"
                  style={{
                    width: "400px",
                    borderRadius: "5px",
                    padding: "8px 12px",
                    fontSize: "18px",
                    marginLeft: "40px"
                  }}
                  value={language}
                  onChange={(e) => setLanguage(e.target.value)}

                >
                  <option value="en-US">English</option>
                  <option value="ja-JP">Japanese</option>
                  
                </select>
              </h5>
              <div className="row" style={{ margin: "10px" }}>
                <div className="col-md-5">
                  <div
                    className="row"
                    style={{ margin: "10px", marginTop: "120px" }}
                  >
                    <button
                      className="btn btn-info"
                      style={{
                        width: "290px",
                        height: "80px",
                        fontSize: "20px",
                        fontFamily: "cursive",
                      }}
                      onClick={zoomMeeting}
                    >
                      Join Meeting
                    </button>
                  </div>
                </div>
                <div className="col-md-7" style={{ float: "right" }}>
                  <img
                    src={meetingimage}
                    height="330px"
                    width="400px"
                    style={{
                      margin: "10px",
                      borderRadius: "50px",
                    }}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </header>
        </div>
      </div>
    </div>
  );
};

export default JoinMeeting;
